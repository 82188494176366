import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../components/video-conferencing/VideoConferencing.css';
import '../../assets/styles/style.css';
import '../../assets/styles/responsive.css';
import Login from '../loginPage/login';
import Otp from '../../components/otpscreen/otp';
import Pin from '../../components/pinscreen/pin';
import DocComp from '../../components/docConsult/DocComp';
import HcConsult from "../../components/hcConsult/hcConsult";
import PrivateRoute from '../../utils/PrivateRoute';
import PatientListComponent from '../patientlist/patientListComponent';
import MyCalendarComponent from '../mycalendar/myCalendarComponent';
import NotFound from '../notFound/notFound';
import Error from '../error/errorComponent';
import Profile from '../../components/profileview/profile';
import pastVisit from '../../components/pastPrescription/pastVisit';
import Edit from '../../components/profileview/edit';
// import FloatingVc from '../../components/video-conferencing/floating-vc';
// import VonageFloatingVc from '../../components/video-conferencing/vonage-floating-vc';
import FloaterWindow from '../../components/video-conferencing/floater-window';
import VcSounds from '../../components/video-conferencing/vc-sounds';
// import FloatingVc from '../../components/video-conferencing/floating-vc';
import ProgressNotes from '../../components/progressNotes/progressNotes';
import patientVitals from '../../components/patientVitals/patientVitals';
import homeCareCard from '../../components/homeCareCard/homeCareCard';
import AllergiesTab from '../../components/allergies/allergies';
// import HeaderComponent from '../../components/header/headerComponent';
import VonageFloatingVc from '../../components/video-conferencing/vonage-floating-vc';
// import IncomingCall from '../../components/video-conferencing/IncomingCall';

function App() {
	return (
		<>
			<Scrollbars style={{ height: '100vh' }} className="scrollBars"
				renderView={props => <div {...props} className="scrollContainer" />}
				renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}>
				<Router>
					<Suspense fallback={<div>Loading...</div>}>
						<Switch>
							<Route exact path="/" component={Login} />
							<Route exact path="/otp" component={Otp} />
							<Route exact path="/pin" component={Pin} />
							<PrivateRoute exact path="/patient-lists" component={PatientListComponent} />
							<PrivateRoute exact path="/my-calendar" component={MyCalendarComponent} />
							<PrivateRoute exact path="/doctor-consult" component={DocComp} />
							<PrivateRoute exact path="/hc-consult" component={HcConsult} />
							<PrivateRoute exact path="/profile" component={Profile} />
							<PrivateRoute exact path="/edit" component={Edit} />
							<PrivateRoute exact path="/progress-notes" component={ProgressNotes} />
							<PrivateRoute exact path="/homecare-card" component={homeCareCard} />
							<PrivateRoute exact path="/past-visit" component={pastVisit} />
							<PrivateRoute exact path="/patient-vitals" component={patientVitals} />
							<PrivateRoute exact path="/allergies" component={AllergiesTab} />
							<Route path="*" component={NotFound} />
							<Route exact component={Error} />
						</Switch>
					</Suspense>
					{/*<VonageFloatingVc />*/}
					{/*<IncomingCall />*/}
					<FloaterWindow />
					<VcSounds />
				</Router>
			</Scrollbars>
		</>
	);
}

export default App;
