import React, { Component } from "react";
import logo from "../../assets/images/logo-myhealth.png";
import axios from "axios";
import OtpInput from "react-otp-input";
import { LOADER, PIN_VERIFY_API, PIN_SAVE_API, HOSPITAL_LIST_API, HOSPITAL_LIST_API_NURSE } from "../../utils/constant";
import LS_SERVICE from "../../utils/localStorage";
import ToastComponent from "../toast/toast";
import { Link } from "react-router-dom";

class Pin extends Component {

    state = {
        uid: LS_SERVICE.get("uid"),
        pin: "",
        is_pin_available: LS_SERVICE.get("is_pin_available"),
        is_forgot_pin: LS_SERVICE.has('is_forgot_pin') ? LS_SERVICE.get('is_forgot_pin') : false,
        pinSubmitDisabled: true,
        resetPinDisabled: false,
        spinner: false,
        isAuthenticated: false,
        toastSuccessClass: "bg-danger",
        toast: false,
        toastmsg: "",
    };

    constructor(props) {
        super(props);
        if (!LS_SERVICE.get("uid"))
            props.history.push("/");
    }

    handlePINChange = (pinVal) => {
        let pin = { ...this.state.pin };
        pin = pinVal.replace(/\D/, "");


        if (pin.length === 4) {
            this.setState({
                pinSubmitDisabled: false,
            });
        } else {
            this.setState({
                pinSubmitDisabled: true,
            });
        }

        this.setState({ pin });
    };

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    forgotPinHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        LS_SERVICE.set('is_forgot_pin', true);
        LS_SERVICE.set('is_pin_available', false);
        this.props.history.push('/otp');
    }

    resetToastState = () => {
        this.setState({
            toastSuccessClass: "bg-danger",
            toast: false,
            toastmsg: "",
        });
    };

    submitHandler = (e) => {
        this.resetToastState();
        e.preventDefault();
        e.stopPropagation();
        const { pin, uid, is_pin_available } = this.state;
        this.setState({
            pinSubmitDisabled: true,
            spinner: true
        })

        const PIN_URL = is_pin_available ? PIN_VERIFY_API : PIN_SAVE_API;
        axios
            .post(PIN_URL, { pin, uid })
            .then((Response) => {
                this.setState({
                    pinSubmitDisabled: false,
                    spinner: false
                })
                const data = Response.data;
                const headers = Response.headers;

                const doctor = data.user_type === 3 ? data.doctor[0] : data.nurse[0];
                const authorization = headers.authorization;
                this.setState({
                    isAuthenticated: true,
                });
                LS_SERVICE.set("doctor", doctor);
                LS_SERVICE.set("authorization", authorization);
                LS_SERVICE.set("is_authenticated", this.state.isAuthenticated);
                LS_SERVICE.set("is_doctor", data.user_type === 3 ? true : false)

                //FECTHING HOSPITAL LIST
                axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("authorization");
                axios.get(data.user_type === 3 ? HOSPITAL_LIST_API : HOSPITAL_LIST_API_NURSE)
                    .then((Response) => {
                        LS_SERVICE.set("hospitals", Response.data.hospitals);
                        this.props.history.push("/patient-lists");
                    })
                    .catch((error) => {
                        console.log(error);
                        if (error && error.response && error.response.status === 401) {
                            LS_SERVICE.clear();
                            this.props.history.push("/");
                        }
                    });

            })
            .catch((error) => {
                this.setState({
                    pinSubmitDisabled: false,
                    spinner: false
                })
                LS_SERVICE.set("is_authenticated", this.state.isAuthenticated);
                error = error.response;
                if (error && error.status === 400) {
                    if (error && error.data && error.data.errors.length > 0) {
                        this.setState({
                            toast: true,
                            toastmsg: error.data.errors[0].message,
                        });
                    }
                }
            });
    };

    render() {
        const { is_pin_available, is_forgot_pin, pin } = this.state;
        return (
            <div className="container-fluid">
                <div className="row justify-content-center full-height">
                    <div className="col-auto d-flex align-self-center">
                        <div className="text-center d-flex align-items-center">
                            <form className="otp-form" onSubmit={this.submitHandler}>
                                <img className="img-fluid otp-logo" src={logo} alt="logo" />
                                <h5 className="virtual-heading otp-virtual-name mt-4">
                                    {" "}
                                    VIRTUAL CONSULT PLATFORM FOR DOCTORS
                                </h5>
                                <p className="text-center mt-5 otp-text bold-text">
                                    {is_pin_available || is_forgot_pin ? 'Enter' : 'Set'} your PIN
                                </p>
                                <div className="form-group pt-5">
                                    <div className="otpinp_wrapper">
                                        <OtpInput
                                            onChange={this.handlePINChange}
                                            shouldAutoFocus={true}
                                            hasErrored={true}
                                            numInputs={4}
                                            isInputNum={false}
                                            value={pin}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-0">
                                    <button
                                        className="btn btn-danger btn-submit otp-btn px-5 mt-5"
                                        disabled={this.state.pinSubmitDisabled}
                                    >
                                        SUBMIT PIN{this.state.spinner ? LOADER : ""}
                                    </button>
                                    {is_pin_available || is_forgot_pin ? <p className="pt-3 m-0">
                                        <a
                                            className='Resend-otp mr-5'
                                            href="!#"
                                            onClick={this.forgotPinHandler}
                                        >
                                            Forgot PIN?
                                        </a>
                                        <Link to="/otp"
                                            className='Resend-otp'>
                                            Login with OTP
                                        </Link>
                                    </p> : ""}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastComponent
                    successClass={this.state.toastSuccessClass}
                    show={this.state.toast}
                    message={this.state.toastmsg}
                />
            </div>
        );
    }
}

export default Pin;
