import * as React from 'react';
import './floating-vc.css';
import { withRouter } from "react-router-dom";
import LS_SERVICE from "../../utils/localStorage";
import axios from "axios";
import {
    API_BASE_URL3
} from "../../utils/constant";

class VonageFloatingVc extends React.Component {

    constructor(props) {
        super(props);
        this.handleFloaterClock = this.handleFloaterClock.bind(this);
    }

    state = {
        activeDrags: 0,
        element: {
            pos1: 0,
            pos2: 0,
            pos3: 0,
            pos4: 0,
            widget: 'floating_vc_dialog'
        },
        exapndScreen: false,
        floatingScreenCss: null
    };

    componentDidMount() {
        window.InncVonage.setFloatingComponentState(this);
    }

    onStart = () => {
        // this.setState({activeDrags: ++this.state.activeDrags});
        this.setState(
            (prevState) => ({
                activeDrags: prevState.activeDrags + 1
            })
        )
    };

    onStop = () => {
        this.setState(
            (prevState) => ({
                activeDrags: prevState.activeDrags - 1
            })
        )
        // this.setState({activeDrags: --this.state.activeDrags});
    };

    handleFloaterClock = (e) => {
        this.props.history.push({
            pathname: "/doctor-consult",
            state: {
                booking_id: document.getElementById('floating_vc_dialog').dataset.booking_id,
            },
        });
    };

    dragElement() {
        var elmnt = document.getElementById('floating_vc_dialog');
        if(elmnt === null) return;
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById('floating_vc_dialog_drag')) {
          // if present, the header is where you move the DIV from:
          document.getElementById('floating_vc_dialog_drag').onmousedown = dragMouseDown;
        } else {
          // otherwise, move the DIV from anywhere inside the DIV:
          /** was brekaing with the following error 'TypeError: Cannot set property 'onmousedown' of null' so added null check at start **/
          elmnt.onmousedown = dragMouseDown;
        }
      
        function dragMouseDown(e) {
          e = e || window.event;
          e.preventDefault();
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }
      
        function elementDrag(e) {
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;

            let topPos = 0;
            if (elmnt.offsetTop <= 0) {
                topPos = 1;
            } else if ((elmnt.offsetTop + elmnt.offsetHeight) >= window.innerHeight) {
                topPos = window.innerHeight - elmnt.offsetHeight - 1;
            } else {
                topPos = elmnt.offsetTop - pos2;
            }

            let leftPos = 0;
            if (elmnt.offsetLeft <= 0) {
                leftPos = 1;
            } else if ((elmnt.offsetLeft + elmnt.offsetWidth) >= window.innerWidth) {
                leftPos = window.innerWidth - elmnt.offsetWidth - 10;
            } else {
                leftPos = elmnt.offsetLeft - pos1;
            }

        //   elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        //   elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
          elmnt.style.top = topPos + "px";
          elmnt.style.left = leftPos + "px";
        }
      
        function closeDragElement() {
          // stop moving when mouse button is released:
          document.onmouseup = null;
          document.onmousemove = null;
        }
    }

    expandVideoScreen() {
        const dialog = document.getElementById('floating_vc_dialog')
        const style = dialog.getAttribute('style');
        if (this.state.exapndScreen && this.state.floatingScreenCss != null) {
            dialog.setAttribute('style', this.state.floatingScreenCss);
        } else {
            dialog.removeAttribute('style');
        }

        this.setState({
            exapndScreen: !this.state.exapndScreen,
            floatingScreenCss: style
        }, () => {
            
        });
    }

    getWidgetClass() {
        setTimeout(() => {
            const obj = document.getElementById('floating_vc_dialog');
            if (obj === null) {
                /** Commented below line as OBJ is null so it gives following error 'TypeError: Cannot read property 'classList' of null'  */
                // document.getElementById('floating_vc_dialog').classList.add("hidden");
                return;
            }
            const bookingId = obj.getAttribute('data-booking_id');
            if (bookingId === null) {
                // this.muteFloatingVideoElem(false);
                document.getElementById('floating_vc_dialog').classList.add("hidden");
                return;
            }

            if (this.props.location.pathname === '/doctor-consult') {
                const patient = document.getElementById('patient_call_widget');
                if (patient != null) {
                    const pid = document.getElementById('patient_call_widget').getAttribute('data-pid');
                    const cpid = document.getElementById('floating_vc_dialog').getAttribute('data-pid');
                    if (pid !== cpid) {
                        // this.muteFloatingVideoElem(true);
                        document.getElementById('floating_vc_dialog').classList.remove("hidden");
                        return;
                    } else {
                        // this.muteFloatingVideoElem(false);
                        document.getElementById('floating_vc_dialog').classList.add("hidden");
                        return;
                    }
                }
            }

            if (!isNaN(bookingId)) {
                // this.muteFloatingVideoElem(true);
                document.getElementById('floating_vc_dialog').classList.remove("hidden");
                LS_SERVICE.set('vc_booking_id', bookingId);
                return;
            }
        }, 1000);
    }

    getOtSessionToken(booking_id) {
        // var $this = this;
        // return new Promise((resolve, reject) => {
            axios.post(API_BASE_URL3 + 'vonage/call', {
                booking_id: booking_id
            })
            .then(function (response) {
                // console.log(response);
                // resolve(response);
            })
            .catch(function (error) {
                // $this.updateVideoCallMsg({
                //     btnStatus: true,
                //     message: 'Session cannot be started, please refresh page to start video call'
                // });
                // resolve({});
            });
        // });
    }

    render() {
        setTimeout(() => {
            this.getWidgetClass();
            this.dragElement();
        }, 500);
        let hideFloatingVC = 'hidden';
        let exapndScreen = this.state.exapndScreen ? 'big-screen' : '';
        let exapndImgScreen = this.state.exapndScreen ? '/assets/images/miniplayer.png' : '/assets/images/expand.png';

        // const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
        return (
            <div className={"floating-vc " + hideFloatingVC + " " + exapndScreen} id="floating_vc_dialog">
                <div className="fvc-container">
                    {/* Video Screen(Subscriber/Patient) will be displayed here */}
                    <div className="fvc-widget" id="float_subscriber_container">
                        <div id="float_subscriber"></div>
                    </div>
                    {/* Video Screen(Publisher/Doctor) will be displayed here - floatingLocalElem */}
                    <div className="fvc-widget publisher-elem" title="Doctor video screen" id="float_publisher_container">
                        <div id="float_publisher"></div>
                    </div>
                    <div className="modal-call-controls">
                        <button onClick={() => this.handleFloaterClock()} type="button" className="control-btn vc-back-btn" title="Return to consult screen">
                            <img src="/assets/images/back.png" alt="Back" />
                        </button>
                        <button id="floating_vc_dialog_drag" type="button" className="control-btn vc-back-btn" title="Hold this icon and drag this whole element">
                            <img src="/assets/images/drag.png" alt="Drag" />
                        </button>
                        <button onClick={() => this.expandVideoScreen()} type="button" className="control-btn" title="Expand screen">
                            <img src={exapndImgScreen} alt="mic-icon"/>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(VonageFloatingVc);
