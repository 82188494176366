import React, { Component } from 'react';
import { Table } from 'reactstrap';
import jpg from "../../assets/images/jpeg.png";
import pdf from "../../assets/images/pdf.png";
import './patientVitals.css';
import graph from "../../assets/images/ic-vitals.png"
import HeaderComponent from '../header/headerComponent';
import LS_SERVICE from "../../utils/localStorage";
import { LOADER_RED, LOADER, PATIENT_VITALS, HOMECARE_GRAPH, TEMPERATURE_TYPE_VALIDATE_FUNCTION, HANDLE_PATIENT_VITALS_COLORS, PATIENT_NOTES, UPDATE_VITALS } from "../../utils/constant";
import { SEPARATEUPLOADEDDOCS, IMAGEPREVNEXTRESET, GETURLEXTENSION, HANDLEIMAGEROTATE } from "../../utils/constant";
import axios from 'axios';
import Moment from "react-moment";
import { withRouter } from "react-router-dom";
import $ from 'jquery';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import prevImgIcon from "../../assets/images/previous-icon.svg";
import nextImgIcon from "../../assets/images/next-icon.svg";
import rotateLeft from "../../assets/images/rotate-left.png";
import rotateRight from "../../assets/images/rotate-right.png";
import HcSubHeaderComponent from '../sub-header-hc/hcsubHeaderComponent';
import Chart from '../docConsult/hcChart';
import Popup from "reactjs-popup";
import icEdit from "../../assets/images/ic-edit-copy-7.svg";
import icredEdit from "../../assets/images/edit-red.svg";
import ToastComponent from "../../components/toast/toast";



const editRea = ["Incorrect reading entered by patient", "Incorrect reading due to faulty machine"]

class patientVitals extends Component {

  state = {
    patientDocments: [],
    notesForDoc: [],
    patVitals: [],
    data: [],
    dataLoaded: false,
    uploads: [],
    modal: false,
    dataFile: "",
    modalClass: "modal-imgdocs",
    documentIndex: 0,
    documentCount: 0,
    documentType: "",
    allDocuments: [],
    subHeaderData: null,
    booking_id: null,
    chartsTab: 0,
    chartsPop: false,
    chartDataLoaded: false,
    chartData: [],
    is_doctor: false,
    date_range_graph: false,
    vitalEditIndex: null,
    isVitalEdit: false,
    editReason: false,
    reasonForChange: "0",
    tempValue: "",
    spo2Value: "",
    bpDiaValue: "",
    bpSysValue: "",
    pulseValue: "",
    rbsValue: "",
    isUpadting: false,
    toastSuccessClass: "bg-danger",
    toast: false,
    toastmsg: "",
    otherReason: ""
  }

  componentWillMount() {
    this.setState({
      subHeaderData: this.props.location.state.subHeaderData,
      booking_id: this.props.location.state.booking_id,
      is_doctor: LS_SERVICE.get('is_doctor')
    })
  }

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );

    const { is_doctor, booking_id } = this.state;

    const PATIENT_VITALS_AXIOS = axios.get(PATIENT_VITALS({ is_doctor, booking_id }))
    const PATIENT_NOTES_AXIOS = axios.get(PATIENT_NOTES({ is_doctor, booking_id }))

    Promise.all([PATIENT_VITALS_AXIOS, PATIENT_NOTES_AXIOS])
      .then((res) => {
        const dataResPatientVitals = res[0].data.patient_vitals;
        const dataResPatientNotes = res[1].data.patient_notes_for_doctor;
        this.setState({
          // patientDocments: dataRes[0].patient_documents,
          // patVitals: dataRes[0].patient_vitals !== null ? dataRes[0].patient_vitals : [],
          patVitals: dataResPatientVitals,
          notesForDoc: dataResPatientNotes !== undefined ? dataResPatientNotes : [],
          dataLoaded: true
        })

      })
      .catch((error) => {
        this.setState({
          dataLoaded: true
        })
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      })


    // axios.get(PATIENT_VITALS({ is_doctor, booking_id }))
    //   .then((res) => {
    //     const dataRes = res.data.patient_vitals;
    //     this.setState({
    //       // patientDocments: dataRes[0].patient_documents,
    //       // patVitals: dataRes[0].patient_vitals !== null ? dataRes[0].patient_vitals : [],
    //       patVitals: dataRes,
    //       dataLoaded: true
    //     })
    //   }).catch((error) => {
    //     this.setState({
    //       dataLoaded: true
    //     })
    //     if (error && error.response && error.response.status === 401) {
    //       LS_SERVICE.clear();
    //       this.props.history.push("/");
    //     }
    //   });

    // axios.get(PATIENT_NOTES({ is_doctor, booking_id }))
    //   .then((res) => {
    //     const dataRes = res.data.patient_notes_for_doctor;
    //     this.setState({
    //       // patientDocments: dataRes[0].patient_documents,
    //       // patVitals: dataRes[0].patient_vitals !== null ? dataRes[0].patient_vitals : [],
    //       notesForDoc: dataRes !== undefined ? dataRes : [],
    //       dataLoaded: true
    //     })
    //   }).catch((error) => {
    //     this.setState({
    //       dataLoaded: true
    //     })
    //     if (error && error.response && error.response.status === 401) {
    //       LS_SERVICE.clear();
    //       this.props.history.push("/");
    //     }
    //   });
  }

  documentToggle = (e, files, isDoc = false) => {
    if (files !== undefined) {
      if (isDoc) {
        this.setState({
          modalClass: "modal-imgdocs modal-docs",
          documentType: 'application/pdf',
        });
      } else {
        this.setState({
          modalClass: "modal-imgdocs",
          documentType: '',
        });
      }
      if (e !== null) {
        e.preventDefault();
        this.setState({
          documentIndex: $(e.currentTarget).index(),
          documentCount: $(e.currentTarget).siblings().length
        }, () => {
          this.setState({
            showArrow: this.state.documentCount > 1 ? true : false,
          })
        })

        this.setState({
          allDocuments: files,
          dataFile: files[$(e.currentTarget).index()],
        });
      } else {
        this.setState({
          dataFile: files,
        })
      }
    }

    this.setState({
      modal: !this.state.modal,
    })
  };

  handleNextPrev = (e) => {
    e.preventDefault();
    const { documentIndex, allDocuments, documentCount } = this.state;
    let docIndex = documentIndex;
    const targetName = e.currentTarget;
    if (targetName.getAttribute("name") === "image-left") {
      if (docIndex > 0) {
        this.setState({
          documentIndex: documentIndex - 1,
          documentType: GETURLEXTENSION(allDocuments[docIndex - 1]),
          dataFile: allDocuments[docIndex - 1]
        }, () => {
          if (this.state.documentType === "application/pdf") {
            this.setState({
              modalClass: "modal-imgdocs modal-docs",
            })
          } else {
            this.setState({
              modalClass: "modal-imgdocs",
            })
          }
          IMAGEPREVNEXTRESET(this.state.dataFile);
        })
      }
    } else {
      if (docIndex < documentCount) {
        this.setState({
          documentIndex: documentIndex + 1,
          documentType: GETURLEXTENSION(allDocuments[docIndex + 1]),
          dataFile: allDocuments[docIndex + 1]
        }, () => {
          if (this.state.documentType === "application/pdf") {
            this.setState({
              modalClass: "modal-imgdocs modal-docs",
            })
          } else {
            this.setState({
              modalClass: "modal-imgdocs",
            })
          }
          IMAGEPREVNEXTRESET(this.state.dataFile);
        })
      }
    }
  }

  handleProgressBtn = () => {
    this.props.history.push("/progress-notes")
  }

  handleAllergiesBtn = () => {
    this.props.history.push("/allergies")
  }

  handleGraphClick = (e, type = 0) => {

    this.setState({
      chartsTab: type,
      chartsPop: true,
      chartDataLoaded: false
    })

    const { date_range_graph } = this.state;

    if (date_range_graph)
      this.handleDateRangeClick();

    e.preventDefault();

    const { booking_id } = this.state;

    axios
      .get(HOMECARE_GRAPH({ booking_id, type }))
      .then((Response) => {
        this.setState({
          chartDataLoaded: true,
          chartData: Response.data.records
        })
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleDateRangeClick = () => {
    this.setState(
      prev => ({
        date_range_graph: !prev.date_range_graph
      })
    )
  }

  handleVitalEdit = (i) => {
    const data = [...this.state.patVitals][i]
    this.setState(prev => ({
      vitalEditIndex: i,
      isVitalEdit: !prev.isVitalEdit,
      tempValue: data.temperature.temperature_value,
      spo2Value: data.spo3.spo_value,
      bpDiaValue: data.bp.bp_diastolic_value,
      bpSysValue: data.bp.bp_systolic_value,
      pulseValue: data.pulse.pulse_value,
      rbsValue: data.rbs.rbs_value,
    }))
  }

  handleSaveEdit = () => {
    this.setState({
      editReason: true
    })
  }



  handleInputChange = (e) => {

    const { name, value } = e.target
    this.setState({
      [name]: value.replace(name==="tempValue" ? /[a-z]/gi : /\D/, "")
    })
  }

  handleEditSave = () => {

    const { tempValue, spo2Value, pulseValue, is_doctor, rbsValue, bpSysValue, bpDiaValue, reasonForChange, patVitals, vitalEditIndex, otherReason } = this.state

    const id = patVitals[vitalEditIndex].id
    const tempType = patVitals[vitalEditIndex].temperature.temperature_type


    this.setState({
      isUpadting: true
    })
    const reasondata = reasonForChange === "2" ? otherReason : editRea[+reasonForChange]

    const params = {
      temperature: tempValue === "-" ? "" : tempValue,
      temperature_type: tempType,
      spo: spo2Value === "-" ? "" : spo2Value,
      pulse: pulseValue === "-" ? "" : pulseValue,
      bp: bpSysValue || bpDiaValue ? bpSysValue + '/' + bpDiaValue : "",
      rbs: rbsValue === "-" ? "" : rbsValue,
      id,
      reason: reasondata
    }
    axios.post(UPDATE_VITALS({ is_doctor }), params)
      .then((res) => {
        const data = res.data
        patVitals[vitalEditIndex] = data.data[0]
        this.setState({
          editReason: false,
          isUpadting: false,
          vitalEditIndex: null
        }, () => this.resetToastState(() => this.setState({
          toastSuccessClass: "bg-success",
          toast: true,
          toastmsg: data.message,
        })))
      })
      .catch((error) => {
        if (error && error.response && error.response.data && error.response.data.errors.length > 0) {
          this.resetToastState(() => this.setState({
            toastSuccessClass: "bg-danger",
            toast: true,
            toastmsg: error.response.data.errors[0].message,
          }))
          this.setState({
            editReason: false
          })
        }
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });

  }


  resetToastState = (cb = "") => {
    this.setState({
      toastSuccessClass: "bg-danger",
      toast: false,
      toastmsg: "",
    }, () => { if (cb !== "") cb() });
  };


  handleReset = () => {
    this.setState({
      vitalEditIndex: null,
      isVitalEdit: false,
      editReason: false,
      reasonForChange: "0",
      tempValue: "",
      spo2Value: "",
      bpDiaValue: "",
      bpSysValue: "",
      pulseValue: "",
      rbsValue: "",
      isUpadting: false,
      otherReason: ""
    })
  }


  render() {
    const { date_range_graph, vitalEditIndex, isVitalEdit, reasonForChange, isUpadting, otherReason,bpDiaValue,bpSysValue } = this.state

    console.log(+reasonForChange)
    console.log((bpSysValue === "-" ? "" : bpSysValue) + '/' + (bpDiaValue === "-" ? "" : bpDiaValue))

    const vitalsValue = [];
    if (this.state.patVitals.length > 0) {
      this.state.patVitals.forEach((value, index) => {
        vitalsValue.push(
          <tr className={value.vital_by === "Nurse" || value.vital_by === "Doctor" ? "" : "red-row"} key={index}>
            <th width="250px" className="text-secondary font-weight-normal" scope="row">
              <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                {value.date.split("T")[0] + value.date.split("T")[1]}
              </Moment>
            </th>
            <td>{value.vital_by}</td>
            {vitalEditIndex === index && isVitalEdit ? (
              <>
                <td className={`text-center vital-edit-input`}><input name="tempValue" value={this.state.tempValue} onChange={this.handleInputChange} /></td>
                <td className={`text-center vital-edit-input`}><input name="spo2Value" value={this.state.spo2Value} onChange={this.handleInputChange} /></td>
                <td className={`text-center vital-edit-input`}><input name="bpSysValue" className="bp-edit-input sys" value={this.state.bpSysValue} onChange={this.handleInputChange} />/<input name="bpDiaValue" className="bp-edit-input dia" value={this.state.bpDiaValue} onChange={this.handleInputChange} /></td>
                <td className={`text-center vital-edit-input`}><input name="pulseValue" value={this.state.pulseValue} onChange={this.handleInputChange} /></td>
                <td className={`text-center vital-edit-input`}><input name="rbsValue" value={this.state.rbsValue} onChange={this.handleInputChange} /></td>
              </>
            ) : (
                <>
                  <td className={`text-center is_update  ${HANDLE_PATIENT_VITALS_COLORS(value.temperature.temperature_status)}`}>
                    <div className="d-flex flex-row justify-content-center toltip-body">
                      <p className="mb-0 updated-value">{value.temperature.temperature_value}{" "}{value.temperature.temperature_value !== "-" ? TEMPERATURE_TYPE_VALIDATE_FUNCTION(value.temperature.temperature_type) : ""}</p>
                      {value.temperature.is_updated === 1 ? (
                        <>
                          <span className="update-dot">&#x25cf;</span>
                          <div className="update-dot-text">
                            <span className="text-left">
                              <p className="font-weight-bold mb-1">Edited by</p>
                              <p>{value.temperature.update_reason.edited_by}&nbsp;
                                <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                  {value.temperature.update_reason.updated_at.split("T")[0] + value.temperature.update_reason.updated_at.split("T")[1]}
                                </Moment>
                              </p>
                              <p className="font-weight-bold mb-0">Reason for Change</p>
                              <p>{value.temperature.update_reason.reason}</p>
                            </span>
                          </div>
                        </>
                      ) : ""}

                    </div>
                  </td>

                  <td className={`text-center is_update  ${HANDLE_PATIENT_VITALS_COLORS(value.spo3.spo_status)}`}>
                    <div className="d-flex flex-row justify-content-center toltip-body">
                      <p className="mb-0 updated-value">{value.spo3.spo_value}</p>
                      {value.spo3.is_updated === 1 ? (
                        <>
                          <span className='update-dot'>&#x25cf;</span>
                          <div className="update-dot-text">
                            <span className="text-left">
                              <p className="font-weight-bold mb-1">Edited by</p>
                              <p>{value.spo3.update_reason.edited_by}&nbsp;
                                <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                  {value.spo3.update_reason.updated_at.split("T")[0] + value.spo3.update_reason.updated_at.split("T")[1]}
                                </Moment>
                              </p>
                              <p className="font-weight-bold mb-0">Reason for Change</p>
                              <p>{value.spo3.update_reason.reason}</p>
                            </span>
                          </div>
                        </>
                      ) : ""}

                    </div>
                  </td>

                  <td className={`text-center is_update`}>
                    <div className="d-flex flex-row justify-content-center toltip-body">
                      <p className="mb-0 updated-value">
                        <span className={HANDLE_PATIENT_VITALS_COLORS(value.bp.bp_systolic_status)}>
                          {value.bp.bp_systolic_value}
                        </span>/
                    <span className={HANDLE_PATIENT_VITALS_COLORS(value.bp.bp_diastolic_status)}>
                          {value.bp.bp_diastolic_value}
                        </span>
                      </p>
                      {value.bp.is_updated === 1 ? (
                        <>
                          <span className="update-dot">&#x25cf;</span>
                          <div className="update-dot-text">
                            <span className="text-left">
                              <p className="font-weight-bold mb-1">Edited by</p>
                              <p>{value.bp.update_reason.edited_by}&nbsp;
                                <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                  {value.bp.update_reason.updated_at.split("T")[0] + value.bp.update_reason.updated_at.split("T")[1]}
                                </Moment>
                              </p>
                              <p className="font-weight-bold mb-0">Reason for Change</p>
                              <p>{value.bp.update_reason.reason}</p>
                            </span>
                          </div>
                        </>
                      ) : ""}

                    </div>
                  </td>
                  <td className={`text-center is_update ${HANDLE_PATIENT_VITALS_COLORS(value.pulse.pulse_status)}`}>
                    <div className="d-flex flex-row justify-content-center toltip-body">
                      <p className="mb-0 updated-value">{value.pulse.pulse_value}</p>
                      {value.pulse.is_updated === 1 ? (
                        <>
                          <span className="update-dot">&#x25cf;</span>
                          <div className="update-dot-text">
                            <span className="text-left">
                              <p className="font-weight-bold mb-1">Edited by</p>
                              <p>{value.pulse.update_reason.edited_by}&nbsp;
                                <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                  {value.pulse.update_reason.updated_at.split("T")[0] + value.pulse.update_reason.updated_at.split("T")[1]}
                                </Moment>
                              </p>
                              <p className="font-weight-bold mb-0">Reason for Change</p>
                              <p>{value.pulse.update_reason.reason}</p>
                            </span>
                          </div>
                        </>
                      ) : ""}

                    </div>
                  </td>
                  <td className={`text-center is_update ${HANDLE_PATIENT_VITALS_COLORS(value.rbs.rbs_status)}`}>
                    <div className="d-flex flex-row justify-content-center toltip-body">
                      <p className="mb-0 updated-value">{value.rbs.rbs_value}</p>
                      {value.rbs.is_updated === 1 ? (
                        <>
                          <span className="update-dot">&#x25cf;</span>
                          <div className="update-dot-text">
                            <span className="text-left">
                              <p className="font-weight-bold mb-1">Edited by</p>
                              <p>{value.rbs.update_reason.edited_by}&nbsp;
                                <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                  {value.rbs.update_reason.updated_at.split("T")[0] + value.rbs.update_reason.updated_at.split("T")[1]}
                                </Moment>
                              </p>
                              <p className="font-weight-bold mb-0 ">Reason for Change</p>
                              <p>{value.rbs.update_reason.reason}</p>
                            </span>
                          </div>
                        </>
                      ) : ""}

                    </div>
                  </td>
                </>
              )}
            <td className={`text-center vitals-edit ${isVitalEdit ? (vitalEditIndex === index ? "active" : "pointer-events-none") : ""}  cursor-pointer `} onClick={() => this.handleVitalEdit(index)}><img src={isVitalEdit && vitalEditIndex === index ? icredEdit : icEdit} /> Edit</td>

          </tr>
        )
      })
    } else {
      vitalsValue.push(
        <tr key="1">
          <td colSpan="7">No Patient Vitals</td>
        </tr>
      )
    }

    const docNotes = [];
    if (this.state.notesForDoc.length > 0) {
      this.state.notesForDoc.forEach((value, index) => {
        const documents = SEPARATEUPLOADEDDOCS(value.testdocument);
        docNotes.push(
          <tr key={index}>
            <td className="text-secondary" scope="row">
              <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                {value.date.split("T")[0] + value.date.split("T")[1]}
              </Moment>
            </td>
            <td className="word-wrap">{value.patient_note_doctor}</td>
            <td>
              {(documents &&
                documents.fileDoc &&
                documents.fileDoc.length > 0) ||
                (documents &&
                  documents.fileImg &&
                  documents.fileImg.length > 0) ? (
                  <div className="">
                    {documents &&
                      documents.fileImg &&
                      documents.fileImg.length > 0
                      ? documents.fileImg.map((file, i) => {
                        return (
                          <button
                            className="btn btn-outline-light p-0 mr-1"
                            key={i}
                            onClick={(e) => this.documentToggle(e, [...documents.fileImg, ...documents.fileDoc])}
                          >
                            <img
                              src={jpg}
                              alt="pdf icon"
                              className="pdf"
                            ></img>
                          </button>
                        );
                      })
                      : ""}

                    {documents &&
                      documents.fileDoc &&
                      documents.fileDoc.length > 0
                      ? documents.fileDoc.map((file, i) => {
                        return (
                          <button
                            className="btn btn-outline-light p-0 mr-1"
                            key={i}
                            onClick={(e) => this.documentToggle(e, [...documents.fileImg, ...documents.fileDoc], true)}
                          >
                            <img
                              src={pdf}
                              alt="pdf icon"
                              className="pdf"
                            ></img>
                          </button>
                        );
                      })
                      : ""}
                  </div>
                ) : (<p className="doc-notes-text no-notes m-0">
                  Nil
                </p>)}
            </td>

          </tr>
        )
      })
    } else {
      docNotes.push(
        <tr key="1">
          <td colSpan="2">No Notes for Doc</td>
        </tr>
      )
    }

    const patDoccuments = []
    if (this.state.patientDocments.length > 0) {
      this.state.patientDocments.forEach((val, index) => {
        const documents = SEPARATEUPLOADEDDOCS(val);
        const date = val[0].date;
        patDoccuments.push(
          <tr key={index}>
            <td className="text-secondary" scope="row">
              <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                {date.split("T")[0] + date.split("T")[1]}
              </Moment>
            </td>
            <td>
              {(documents &&
                documents.fileDoc &&
                documents.fileDoc.length > 0) ||
                (documents &&
                  documents.fileImg &&
                  documents.fileImg.length > 0) ? (
                  <div className="">
                    {documents &&
                      documents.fileImg &&
                      documents.fileImg.length > 0
                      ? documents.fileImg.map((file, i) => {
                        return (
                          <button
                            className="btn btn-outline-light p-0 mr-1"
                            key={i}
                            onClick={(e) => this.documentToggle(e, [...documents.fileImg, ...documents.fileDoc])}
                          >
                            <img
                              src={jpg}
                              alt="pdf icon"
                              className="pdf"
                            ></img>
                          </button>
                        );
                      })
                      : ""}

                    {documents &&
                      documents.fileDoc &&
                      documents.fileDoc.length > 0
                      ? documents.fileDoc.map((file, i) => {
                        return (
                          <button
                            className="btn btn-outline-light p-0 mr-1"
                            key={i}
                            onClick={(e) => this.documentToggle(e, [...documents.fileImg, ...documents.fileDoc], true)}
                          >
                            <img
                              src={pdf}
                              alt="pdf icon"
                              className="pdf"
                            ></img>
                          </button>
                        );
                      })
                      : ""}
                  </div>
                ) : (<p className="doc-notes-text no-notes m-0">
                  Nil
                </p>)}
            </td>

          </tr>
        )
      })
    } else if (this.state.patientDocments.length === 0) {
      patDoccuments.push(
        <tr key="1">
          <td colSpan="2">
            No Documents uploaded
          </td>
        </tr>
      )
    }

    return (
      <>
        <HeaderComponent />
        <HcSubHeaderComponent data={this.state.subHeaderData} />
        <div className="container-fluid custom-container">
          <div className="row d-flex">
            <div className="col-6">
              <h5 className="mb-3 mt-4">Patient Vitals</h5>
            </div>
            <div className={`col-6 d-flex flex-row justify-content-end align-items-center  ${this.state.dataLoaded ? "visible" : "hidden"}`}>
              <p className="p-recordedvitals"><span className="recorded-pat-label"></span>Recorded by patient</p>
              <h4 className="vital-cursor"
                onClick={this.handleGraphClick}>
                <img
                  src={graph}
                  alt="pdf icon"
                  className="pdf"
                ></img>
              </h4>
            </div>
          </div>
          <span className={`${this.state.dataLoaded ? "visible" : "hidden"} patient-vital-table-container`}>
            <div className="bg-white-container px-3 py-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-table">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th width="250px">Date</th>
                          <th>Recorded By</th>
                          <th className="text-center">Temperature</th>
                          <th className="text-center">SpO2 (%)</th>
                          <th className="text-center">BP (mmHg)</th>
                          <th className="text-center">Pulse (bpm)</th>
                          <th className="text-center">RBS (mg/dL)</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vitalsValue}
                      </tbody>
                    </Table>
                  </div>
                  {isVitalEdit ? <button className="btn btn-outline-danger float-right font-weight-bold py-1" onClick={() => this.handleSaveEdit()}>Save</button> : ""}
                </div>
              </div>

            </div>
            <div className="row my-4">
              <div className="col-md-12">
                <h5 className="mb-3">Notes For Doctor</h5>
                <div className="bg-white-container px-3 py-3">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Notes</th>
                        <th>Documents</th>
                      </tr>
                    </thead>
                    <tbody>
                      {docNotes}
                    </tbody>
                  </Table>

                </div>
              </div>
              {/* <div className="col-md-6">
                <h5 className="mb-3">Documents Uploaded</h5>
                <div className="bg-white-container px-3 py-3">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th width="200px">Date</th>
                        <th>Documents</th>
                      </tr>
                    </thead>
                    <tbody>
                      {patDoccuments}
                    </tbody>
                  </Table>

                </div>
              </div> */}
            </div>
          </span>
          <div
            className="col d-flex justify-content-center align-self-center mt-2"
            style={{
              visibility: `${!this.state.dataLoaded ? "visible" : "hidden"}`,
            }}
          >
            {LOADER_RED}
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          toggle={this.documentToggle}
          className={this.state.modalClass}
        >
          <ModalHeader toggle={this.documentToggle}></ModalHeader>
          <ModalBody>
            <div className="img-rotate">
              <i className="ir-left" onClick={HANDLEIMAGEROTATE} name="rotate-left"><img className="rotate-left" src={rotateLeft} /></i>
              <i className="ir-right" onClick={HANDLEIMAGEROTATE} name="rotate-right"><img className="rotate-left" src={rotateRight} /></i>
            </div>
            <div className="img-arrows" style={{ 'display': this.state.showArrow ? 'flex' : 'none' }}>
              <i className="ir-left" onClick={this.handleNextPrev} name="image-left"><img className="image-left" src={prevImgIcon} /></i>
              <i className="ir-right" onClick={this.handleNextPrev} name="image-right"><img className="image-left" src={nextImgIcon} /></i>
            </div>
            <div className="object-wrapper ow-fullheight">
              <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0"></object>
            </div>
          </ModalBody>
        </Modal>



        <Popup
          className="homecare-charts-modal"
          open={this.state.chartsPop}
          onClose={() => this.setState({ chartsPop: false })}
          modal
        >
          <div className="popup px-3">
            <h5 className=" mt-4">Vitals Trend</h5>
            <div className="close" onClick={() => this.setState({ chartsPop: false })}>
              &times;
            </div>
            <div className="content emrfrm">
              <div className="row">
                <div className="col-12">
                  <button className={`btn btn-outline-danger date-range ${date_range_graph ? "active" : ""} `}
                    onClick={() => this.handleDateRangeClick()}>
                    Date Range
                    </button>
                  <nav className="homeCare-charts nav nav-tabs" role="tablist">
                    <a id="controlled-tab-example-tab-Days" href="#" role="tab"
                      className={`nav-item nav-link ${!date_range_graph && this.state.chartsTab === 0 ? 'active' : ''}`}
                      onClick={(e) => this.handleGraphClick(e, 0)}>DAY</a>
                    <a id="controlled-tab-example-tab-weeks" href="#" role="tab"
                      className={`nav-item nav-link ${!date_range_graph && this.state.chartsTab === 1 ? 'active' : ''}`}
                      onClick={(e) => this.handleGraphClick(e, 1)}>WEEK</a>
                    <a id="controlled-tab-example-tab-months" href="#" role="tab"
                      className={`nav-item nav-link ${!date_range_graph && this.state.chartsTab === 2 ? 'active' : ''}`}
                      onClick={(e) => this.handleGraphClick(e, 2)}>MONTH</a>
                    <a id="controlled-tab-example-tab-years" href="#" role="tab"
                      className={`nav-item nav-link ${!date_range_graph && this.state.chartsTab === 3 ? 'active' : ''}`}
                      onClick={(e) => this.handleGraphClick(e, 3)}>YEAR</a>
                  </nav>
                  <div className="tab-content">
                    <Chart chartData={this.state.chartData} dataLoaded={this.state.chartDataLoaded} is_doctor={this.state.is_doctor} booking_id={this.state.booking_id} type={!date_range_graph && this.state.chartsTab} date_range={this.state.date_range_graph} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Popup>

        <Popup
          className="homecare-vitals-edit"
          open={this.state.editReason}
          onClose={() => this.handleReset()}
          modal
        >
          <div className="popup px-3">
            <h5 className=" mt-2">Reason for change</h5>
            <div className="close" onClick={() => this.setState({ editReason: false })}>
              &times;
            </div>
            <div className="content emrfrm">
              <div className=" d-flex flex-column my-4">
                <label className="radio patient-name homeCare font-weight-normal mr-3">Incorrect reading entered by patient
                  <input type="radio" value="0"
                    name="reasonForChange"
                    checked={reasonForChange === "0"}
                    onChange={this.handleInputChange} />
                  <span className="checkround homecare"></span>
                </label>
                <label className="radio patient-name homeCare font-weight-normal mr-3">Incorrect reading due to faulty machine
                  <input type="radio" value="1"
                    name="reasonForChange"
                    checked={reasonForChange === "1"}
                    onChange={this.handleInputChange} />
                  <span className="checkround homecare"></span>
                </label>
                <label className="radio patient-name homeCare font-weight-normal mr-3">Other reason
                  <input type="radio" value="2"
                    name="reasonForChange"
                    checked={reasonForChange === "2"}
                    onChange={this.handleInputChange} />
                  <span className="checkround homecare"></span>
                </label>
                <textarea className={`other-reason-txt mt-2 ${reasonForChange !== "2" ? "d-none" : ""}`} name="otherReason" value={otherReason} onChange={(e) => this.setState({ otherReason: e.target.value })}></textarea>
              </div>
              <div className="mb-3 d-flex justify-content-center">
                <button className="btn btn-outline-secondary font-weight-bold mr-2 py-1 px-3" onClick={() => this.setState({ editReason: false })} >CANCEL</button>
                <button className="btn btn-primary px-4 font-weight-bold" onClick={() => this.handleEditSave()}>
                  SAVE{isUpadting ? LOADER : ""}
                </button>
              </div>
            </div>
          </div>
        </Popup>

        <ToastComponent
          successClass={this.state.toastSuccessClass}
          show={this.state.toast}
          message={this.state.toastmsg}
        />
      </>
    );
  }
}

export default withRouter(patientVitals);