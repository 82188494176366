import React from 'react';
import { Line } from 'react-chartjs-2';
import { LOADER_RED, JQUERYLABELS, GRAPH_CUSTOM_DATES } from '../../utils/constant';
import calendar from '../../assets/images/calendar.png'
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import LS_SERVICE from "../../utils/localStorage";

export default class Chart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataLoaded: false,
            is_doctor: false,
            booking_id: null,
            start_date: this.getOldDate(),
            end_date: new Date(),
            type: 0,
            date_range: false,
            charts_while_filter: false
        }
    }

    getOldDate = () => {
        let x = new Date();
        let y = x.getDate() - 7;
        x.setDate(y);
        return x;
    }

    componentWillReceiveProps(props) {
        this.setState({
            data: props.chartData,
            dataLoaded: props.dataLoaded,
            is_doctor: props.is_doctor,
            booking_id: props.booking_id,
            type: props.type,
            date_range: props.date_range,
            charts_while_filter: props.date_range
        });
    }

    componentDidMount() {
        const fields = $(".emrfrm .form-control");
        fields.each((i, e) => {
            JQUERYLABELS(e);
        });

        $(document).on("focusin", ".emrfrm .form-control", function () {
            JQUERYLABELS(this);
        });
    }

    handleDateChange = (datename, date) => {
        // if (datename === "start_date") {
        //     this.setState({
        //         start_date: date
        //     })
        // } else {
        this.setState({
            [datename]: date,
            charts_while_filter: false
        }, () => this.handleCustomDate())
        // }
    };

    handleCustomDate = () => {

        let { is_doctor, booking_id, start_date, end_date } = this.state
        this.setState({
            dataLoaded: false
        })

        start_date = start_date.toISOString().split('T')[0];
        end_date = end_date.toISOString().split('T')[0]


        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("authorization");
        axios.get(GRAPH_CUSTOM_DATES({ booking_id, is_doctor, start_date, end_date }))
            .then(res => {
                this.setState({
                    data: res.data.records,
                    dataLoaded: true
                })
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
            })
    }


    render() {

        console.log(this.state.data)

        const tempData = {
            labels: this.state.data.dates,
            // labels: ["1-Aug", "2-Aug", "3 Aug", "4 Aug","5 Aug", "6-Aug", "7-Aug", "8-Aug","9-Aug", "10-Aug"],
            datasets: [
                {
                    label: '',
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: '#FF0000',
                    borderColor: '#FF0000',
                    borderWidth: 1,
                    spanGaps: true,
                    data: this.state.data.temperature,
                }
            ]
        }

        const tempOptions = {
            responsive: true,
            title: {
                display: true,
                text: 'Temperature(F)',
                fontSize: 15
            },

            legend: {
                position: "bottom",
                display: false,
                labels: {
                    boxWidth: 6,
                    fontColor: '#333333',
                    usePointStyle: true,
                },
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: '',
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 5
                    }
                }],
                yAxes: [{
                    position: 'left',
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: '',
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 8
                    }
                }]
            },
        }

        const spo2Data = {
            labels: this.state.data.dates,
            datasets: [
                {
                    label: '',
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: '#FF0000',
                    borderColor: '#FF0000',
                    borderWidth: 1,
                    spanGaps: true,
                    data: this.state.data.spo
                }
            ]
        }

        const spo2Options = {
            responsive: true,
            title: {
                display: true,
                text: 'SpO2(%)',
                fontSize: 15
            },

            legend: {
                position: "bottom",
                display: false,
                labels: {
                    boxWidth: 6,
                    fontColor: '#333333',
                    usePointStyle: true
                }
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: ''
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 5
                    }
                }],
                yAxes: [{
                    position: 'left',
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: '',
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 8
                    }
                }]
            },
        }

        const BpData = {
            labels: this.state.data.dates,
            datasets: [
                {
                    label: 'Systolic',
                    fill: false,
                    lineTension: 1,
                    backgroundColor: '#FF0000',
                    borderColor: '#FF0000',
                    borderWidth: 1,
                    spanGaps: true,
                    data: this.state.data.systolic
                },
                {
                    label: 'Diastolic',
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: '#126dcb',
                    borderColor: '#126dcb',
                    borderWidth: 1,
                    spanGaps: true,
                    data: this.state.data.dystolic
                }
            ]
        }

        const BpOptions = {
            responsive: true,
            title: {
                display: true,
                text: 'Blood Pressure (mmHg)',
                fontSize: 15
            },

            legend: {
                position: "bottom",
                display: true,
                labels: {
                    boxWidth: 6,
                    fontColor: '#333333',
                    usePointStyle: true
                }
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: ''
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 5
                    }
                }],
                yAxes: [{
                    position: 'left',
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: '',
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 8
                    }
                }]
            },
        }

        const pulseData = {
            labels: this.state.data.dates,
            datasets: [
                {
                    label: '',
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: '#FF0000',
                    borderColor: '#FF0000',
                    borderWidth: 1,
                    spanGaps: true,
                    data: this.state.data.pulse
                }
            ]
        }

        const pulseOptions = {
            responsive: true,
            title: {
                display: true,
                text: 'Pulse (bpm)',
                fontSize: 15
            },

            legend: {
                position: "bottom",
                display: false,
                labels: {
                    boxWidth: 6,
                    fontColor: '#333333',
                    usePointStyle: true
                }
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: ''
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 5
                    }
                }],
                yAxes: [{
                    position: 'left',
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: '',
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 8
                    }
                }]
            },
        }

        const rbsData = {
            labels: this.state.data.dates,
            datasets: [
                {
                    label: '',
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: '#FF0000',
                    borderColor: '#FF0000',
                    borderWidth: 1,
                    spanGaps: true,
                    data: this.state.data.rbs
                }
            ]
        }

        const rbsOptions = {
            responsive: true,
            title: {
                display: true,
                text: 'RBS (mg/dL)',
                fontSize: 15
            },

            legend: {
                position: "bottom",
                display: false,
                labels: {
                    boxWidth: 6,
                    fontColor: '#333333',
                    usePointStyle: true
                }
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: ''
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 5
                    }
                }],
                yAxes: [{
                    position: 'left',
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: '',
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 8
                    }
                }]
            },
        }
        console.log(this.state.date_range, this.state.charts_while_filter)

        return (
            <>
                <div className={`emrfrm ${!this.state.date_range ? "d-none" : ""}`}>
                    <div className="row justify-content-end">
                        <div className="col-lg-2 pr-1 cus-ip">
                            <div className="form-group d-flex hasdata ">
                                <DatePicker
                                    className="form-control input-addon"
                                    selected={this.state.start_date}
                                    onChange={date => this.handleDateChange("start_date", date)}
                                    selectsStart
                                    startDate={this.state.start_date}
                                    endDate={this.state.end_date}
                                    maxDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    style={{ width: "100%" }}
                                    tabIndex={1}
                                />
                                <span className="input-group-addon">
                                    <img className="calendar-ic" src={calendar} alt="calendar" />
                                </span>
                                <label className="required" htmlFor="keyword">Start Date<span className="text-secondary">*</span></label>
                            </div>
                        </div>
                        <div className="col-2 pl-1 cus-ip">
                            <div className="form-group d-flex hasdata">
                                <DatePicker
                                    className="form-control input-addon"
                                    selected={this.state.end_date}
                                    onChange={date => this.handleDateChange("end_date", date)}
                                    selectsEnd
                                    startDate={this.state.start_date}
                                    endDate={this.state.end_date}
                                    maxDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    style={{ width: "100%" }}
                                    tabIndex={2}
                                />
                                <span className="input-group-addon">
                                    <img className="calendar-ic" src={calendar} alt="calendar" />
                                </span>
                                <label className="required" htmlFor="last_name">End date<span className="text-secondary">*</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.dataLoaded && this.state.data.dates.length > 0 ? (

                    <div className={`row ${this.state.charts_while_filter ? "d-none" : ""}`}>
                        <div className="col-lg-4 charts-bg">
                            <div>
                                <Line
                                    data={tempData}
                                    options={tempOptions}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 charts-bg">
                            <div>
                                <Line
                                    data={spo2Data}
                                    options={spo2Options}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 charts-bg">
                            <div>
                                <Line
                                    data={BpData}
                                    options={BpOptions}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 charts-bg">
                            <div>
                                <Line
                                    data={pulseData}
                                    options={pulseOptions}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 charts-bg">
                            <div>
                                <Line
                                    data={rbsData}
                                    options={rbsOptions}
                                />
                            </div>
                        </div>
                    </div>
                ) : this.state.dataLoaded && this.state.data.dates.length === 0 ? (
                    <div
                        className="col d-flex justify-content-center align-self-center mt-2"
                        style={{
                            visibility: `${!this.state.charts_while_filter ? "visible" : "hidden"}`,
                        }}
                    >
                        No Data
                    </div>
                ) : ""}

                <div
                    className="col d-flex justify-content-center align-self-center mt-2"
                    style={{
                        visibility: `${!this.state.dataLoaded ? "visible" : "hidden"}`,
                    }}
                >
                    {LOADER_RED}
                </div>
            </>
        );
    }
}