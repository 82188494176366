import React, { Component } from 'react';
import PubNub from 'pubnub';
import './pubnub.css';
import { PubNubProvider, PubNubConsumer } from 'pubnub-react';
import { PUBNUBPUBLISHKEY, PUBNUBSUBSCRIBEKEY, GETDOB, UHID, GETGENDER, LOADER_RED } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import Moment from 'react-moment';
import $ from 'jquery';
import rotateLeft from "../../assets/images/rotate-left.png";
import rotateRight from "../../assets/images/rotate-right.png";
import logoMax from "../../assets/images/logo-max.png";
import { LOADER_BLACK } from '../../utils/constant';
import { Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import { HANDLEIMAGEROTATE } from "../../utils/constant";
// import { Recorder } from '../../../../public/assets/js/recorder/recorder';

const pubnub = new PubNub({
    publishKey: PUBNUBPUBLISHKEY,
    subscribeKey: PUBNUBSUBSCRIBEKEY,
    uuid: LS_SERVICE.has('doctor') ? LS_SERVICE.get('doctor').id : ""
});


let context = "";
let source = "";
let processor = "";
let audioData = "";
let escListener = "";
let chatScroll = "";
let recorderEvent = "";
let recorderTimeOut = "";
let recorderTimeoutTime = 60000;

class PubNubComponent extends Component {

    state = {
        channels: [],
        pubNubChannels: [],
        activeChannel: null,
        pushChannels: [],
        doctor_uuid: null,
        messages: [],
        notification_message_text: "",
        isFileBefore: false,
        messages_loaded: false,
        patient_details: undefined,
        message: "",
        timetoken: "",
        fetchendTime: new Date().getTime() * 1000,
        isTyping: null,
        isPubNubFetchRequestProcessing: false,
        pubNubPopupOpen: false,
        noMorePunNubData: false,
        historyMessageLength: 25,
        modal: false,
        dataFile: "",
        modalClass: "modal-imgdocs",
        documentIndex: 0,
        documentCount: 0,
        documentType: "",
        allDocuments: [],
    }

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(props) {
        let { channels, pubNubChannels, patient_details } = this.state;

        if (props.pubnubActiveChatData !== undefined) {

            if (JSON.stringify(pubNubChannels) !== JSON.stringify(props.pubnubActiveChatData)) {
                pubNubChannels = props.pubnubActiveChatData.map(e => e.booking_id);

                this.setState({
                    pubNubChannels
                })
            }
        }

        if (props.data !== undefined) {
            if (channels.filter(e => e === props.data.id).length === 0) {
                channels.push(props.data.id);
                patient_details = props.data.patient;
                this.setState({
                    channels,
                    patient_details,
                    pubNubPopupOpen: props.show,
                    activeChannel: props.data.id,
                    doctor_uuid: LS_SERVICE.get('doctor').id
                }, () => {

                    const { activeChannel } = this.state;

                    if (activeChannel !== null) {
                        this.handleEventListeners();
                        this.handleFetchMessages();
                    }
                })
            }
        }
    }

    handleEventListeners = () => {

        if (chatScroll === "") {
            chatScroll = document.querySelector(".pubnub_scroll_loader");
            if (chatScroll !== null)
                chatScroll.addEventListener("scroll", this.handlePubNubScroll, true);
        }

        if (escListener === "") {
            escListener = document.addEventListener('keydown', this.handleEscapeListener, true);
        }

        document.getElementById("inputText").focus();
    }

    handleEscapeListener = (event) => {
        const { modal } = this.state;
        const key = event.keyCode; // const {key} = event; in ES6+

        if (key === 27 && !modal) {
            this.handlePubNubShowCloseSL(null);
        }
    }

    handlePushPubnubMessages = () => {

        const { pushChannels } = this.state;

        pubnub.subscribe({
            channels: [...pushChannels]
        });

        // debugger;

        // pubnub.getMessage(...pushChannels, (msg) => {
        //     this.notifyMe(msg.message.text);
        // })

    }

    componentDidMount() {
        this.setState({
            doctor_uuid: LS_SERVICE.get('doctor').id
        })
    }

    // componentWillUnmount() {
    //     const { pubNubChannels } = this.state;
    //     inputFile.removeEventListener('change', this.handleImageUpload, true);
    //     pubnub.unsubscribe({ "channels": [...pubNubChannels] });
    //     this.setState({ channels: [] })
    // }

    handleFetchMessages = () => {

        let { fetchendTime, isPubNubFetchRequestProcessing, noMorePunNubData, historyMessageLength, messages, channels } = this.state;

        if (isPubNubFetchRequestProcessing) return;

        this.setState({
            isPubNubFetchRequestProcessing: true
        })

        if (noMorePunNubData) return;

        if (channels.length !== 0) {

            let fetchParams = {
                channels: [...channels],
                count: historyMessageLength
            }

            if (messages.length > 0) {
                fetchParams.start = fetchendTime;
            } else {
                fetchParams.end = fetchendTime;
            }

            pubnub.fetchMessages(
                fetchParams,
                (status, response) => {

                    if (response.channels[channels[0]] !== undefined) {

                        const data = response.channels[channels[0]];

                        if (JSON.stringify(fetchendTime) !== data[0].timetoken) {

                            let newMessages = [];

                            data.forEach(e => {
                                if (e.messageType !== 4) {
                                    newMessages.push({
                                        message: e.message.message,
                                        uuid: e.message.uuid,
                                        name: e.message.name,
                                        timetoken: e.timetoken
                                    })
                                } else {
                                    newMessages.push({
                                        message: e.message.file.name,
                                        file_id: e.message.file.id,
                                        audio: e.message.message.audio !== undefined ? e.message.message.audio : this.handleAudioCheck(e.message.file.name),
                                        uuid: e.message.message.uuid,
                                        name: e.message.message.name,
                                        timetoken: e.timetoken
                                    })
                                }
                            })

                            this.setState({
                                messages: [...newMessages, ...messages],
                                fetchendTime: +data[0].timetoken,
                                isPubNubFetchRequestProcessing: false,
                                messages_loaded: true,
                                noMorePunNubData: data.length < historyMessageLength
                            })
                        }
                    } else {
                        this.setState({
                            isPubNubFetchRequestProcessing: false,
                            noMorePunNubData: true,
                            messages_loaded: true,
                        })
                    }
                }
            );
        }

    }

    handleImageUpload = async (input, audio = false) => {

        const { channels, doctor_uuid } = this.state;

        const file = input.currentTarget.files[0];

        const PAYLOAD = {
            notification: {
                body: '',
                title: "Chat with Tarun Kinger"
            },
            data: {
                message: '',
                channelId: channels[0],
                name: LS_SERVICE.get('doctor').name,
                uuid: doctor_uuid
            }
        }

        pubnub.sendFile({
            channel: channels[0],
            file: file,
            message: {
                pc_gcm: PAYLOAD,
                audio,
                name: LS_SERVICE.get('doctor').name,
                uuid: doctor_uuid
            }
        });

        setTimeout(() => {
            pubnub.publish(
                {
                    channel: channels[0],
                    message: {
                        pn_gcm: PAYLOAD,
                        message: '',
                        channelId: channels[0],
                        name: LS_SERVICE.get('doctor').name,
                        uuid: doctor_uuid
                    },
                });
        }, 0)
    }

    addMessage = newmessage => {

        let { messages, timetoken } = this.state;

        if (timetoken !== newmessage.timetoken) {
            messages = [...messages, {
                message: newmessage.message.message,
                timetoken: newmessage.timetoken,
                name: newmessage.message.name,
                uuid: newmessage.message.uuid,
                file_id: newmessage.file_id,
                audio: newmessage.message.audio
            }];
            this.setState({
                messages,
                timetoken: newmessage.timetoken
            })
        }

        // pubnub.addMessageAction(
        //     {
        //         channel: channels[0],
        //         messageTimetoken: this.state.timetoken,
        //         action: {
        //             type: 'receipt',
        //             value: 'message_read',
        //         },
        //     },
        //     function (status, response) {
        //         debugger
        //     }
        // );
    }

    setMessage = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        // if (value.length >= 1) {
        //     pubnub.signal({
        //         channel: channels[1],
        //         message: {
        //             isTyping: true,
        //             uuid: LS_SERVICE.get('doctor').id
        //         }
        //     })
        // } else if (value.length === 0) {
        //     pubnub.signal({
        //         channel: channels[1],
        //         message: {
        //             isTyping: false,
        //             uuid: LS_SERVICE.get('doctor').id
        //         }
        //     })
        // }
    }

    sendMessage = (e, message) => {
        if (e !== null)
            e.preventDefault();

        const { channels, doctor_uuid } = this.state;

        // let payload = {
        //     message,
        //     name: LS_SERVICE.get('doctor').name,
        //     uuid: LS_SERVICE.get('doctor').id
        // }

        // debugger

        // let pushData = pubnub.notificationPayload(`Chat with Patient`, message);

        // let pushPayload = pushData.buildPayload(["fcm"]);

        // Object.assign(payload, pushPayload);

        // debugger

        // pubnub.publish(
        //     {
        //         channel: channels[0],
        //         message: payload
        //     },
        //     () => this.setState({ message: "" })
        // )

        const PAYLOAD = {
            notification: {
                body: message,
                title: "Chat with Tarun Kinger"
            },
            data: {
                message,
                channelId: channels[0],
                name: LS_SERVICE.get('doctor').name,
                uuid: doctor_uuid
            }
        }

        pubnub.publish(
            {
                channel: channels[0],
                message: {
                    pn_gcm: PAYLOAD,
                    message,
                    channelId: channels[0],
                    name: LS_SERVICE.get('doctor').name,
                    uuid: doctor_uuid
                },
            },
            () => this.setState({ message: "" })
        );

        // pubnub.signal({
        //     channel: channels[1],
        //     message: {
        //         isTyping: false,
        //         uuid: LS_SERVICE.get('doctor').id
        //     }
        // })
    };

    // getThumbNail = (data) => {
    //     const file = this.getFileUrl(data);
    //     debugger;
    // }

    getFileUrl = (data) => {

        const { channels } = this.state;

        return pubnub.getFileUrl({
            channel: channels[0],
            id: data.file_id,
            name: data.message
        });

        // const file = await pubnub.downloadFile({
        //     channel: channels[0],
        //     id: data.file_id,
        //     name: data.message
        //   });


        // debugger
        // const myImageTag = document.createElement('img');
        // myImageTag.src = URL.createObjectURL(await file.toFile());

        // document.body.appendChild(myImageTag);
    }

    showTyping = (data) => {

        let { isTyping } = this.state;

        isTyping = {
            status: data.message.isTyping,
            uuid: data.message.uuid
        }

        this.setState({
            isTyping
        })
    }

    recordAudio = () => {
        return new Promise(resolve => {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    // const mediaRecorder = new MediaRecorder(stream);
                    // const audioChunks = [];

                    const AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;

                    let audioContext = new AudioContext();

                    let gumStream = stream;

                    let input = audioContext.createMediaStreamSource(stream);

                    let rec = "";

                    rec = new window.Recorder(input, { numChannels: 1 });

                    // mediaRecorder.addEventListener("dataavailable", event => {
                    //     audioChunks.push(event.data);
                    // });

                    const start = () => {
                        // mediaRecorder.start();
                        rec.record();
                    };

                    const stop = () => {
                        return new Promise(resolve => {

                            rec.stop();

                            //stop microphone access
                            gumStream.getAudioTracks()[0].stop();

                            //create the wav blob and pass it on to createDownloadLink

                            rec.exportWAV((blob) => {
                                // debugger

                                const inputFile = {
                                    currentTarget: {
                                        files: [
                                            new File([blob], "my_audio.wav", { type: blob.type, lastModified: new Date() })
                                        ]
                                    }
                                }
                                // const audioUrl = URL.createObjectURL(blob);
                                // console.log(audioUrl);
                                // const audio = new Audio(audioUrl);
                                // console.log(audio);

                                // const inputBlob = {
                                //     files: [{
                                //         name: "blob",
                                //         size: blob.size,
                                //         type: blob.type,
                                //         lastModified: new Date().getTime()
                                //     }]
                                // }

                                // let message = btoa(blob);

                                // this.sendMessage(null, message)
                                this.handleImageUpload(inputFile, true);

                            });

                            // const play = () => {
                            //     audio.play();
                            // };

                            // mediaRecorder.addEventListener("stop", () => {
                            //     console.log(audioChunks);
                            //     const audioBlob = new Blob(audioChunks);
                            //     console.log(audioBlob);
                            //     const audioUrl = URL.createObjectURL(audioBlob);
                            //     console.log(audioUrl);
                            //     const audio = new Audio(audioUrl);
                            //     console.log(audio);

                            //     rec.stop();

                            //     //stop microphone access
                            //     gumStream.getAudioTracks()[0].stop();

                            //     //create the wav blob and pass it on to createDownloadLink
                            //     rec.exportWAV();

                            //     // const inputBlob = {
                            //     //     files: [{
                            //     //         name: "recording.webm",
                            //     //         size: audioBlob.size,
                            //     //         type: "audio/webm",
                            //     //         lastModified: 1599628466236,
                            //     //         lastModifiedDate: 'Wed Sep 09 2020 10:44:26 GMT+0530 (India Standard Time) {}'
                            //     //     }]
                            //     // }
                            //     // this.handleImageUpload(inputBlob);
                            //     const play = () => {
                            //         // audio.play();
                            //     };

                            //     resolve({ audioBlob, audioUrl, play });
                            // });

                            // mediaRecorder.stop();
                        });
                    };

                    resolve({ start, stop });
                });
        });
    };

    // handleAudioUpload = () => {

    //     const { audio } = this.state;

    //     // const player = document.getElementById('inputAudioFile');

    //     const handleSuccess = (stream) => {

    //         const { audio } = this.state;

    //         const mediaRecorder = new MediaRecorder(stream);

    //         // if(!audio) {
    //         //     mediaRecorder.stop()
    //         // }

    //         mediaRecorder.start();

    //         const audioChunks = [];

    //         mediaRecorder.addEventListener("dataavailable", event => {
    //             audioChunks.push(event.data);
    //         });

    //         mediaRecorder.addEventListener("stop", () => {
    //             const audioBlob = new Blob(audioChunks);
    //             const audioUrl = URL.createObjectURL(audioBlob);
    //             const audioPlay = new Audio(audioUrl);
    //             audioPlay.play();
    //         });

    //         setTimeout(() => {
    //             mediaRecorder.stop();
    //         }, 3000);

    //         // if (context === "") {
    //         //     context = new (window.AudioContext || window.webkitAudioContext)();
    //         //     source = context.createMediaStreamSource(stream);
    //         //     processor = context.createScriptProcessor(1024, 1, 1);
    //         // }

    //         // if (audio) {
    //         //     source.connect(processor);
    //         //     processor.connect(context.destination);
    //         //     processor.onaudioprocess = (e) => {
    //         //         // Do something with the data, e.g. convert it to WAV
    //         //         console.log(e.inputBuffer);
    //         //         audioData = e.inputBuffer;
    //         //     };
    //         // } else {
    //         //     context.close();
    //         //     source.disconnect(processor);
    //         //     processor.disconnect();
    //         //     context = source = processor = "";
    //         //     player.src = audioData;
    //         // }
    //     };

    //     this.setState({
    //         audio: !audio,
    //     },
    //         () => {
    //             navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    //                 .then(handleSuccess)
    //                 .catch(err => console.log(err))
    //         })
    // }

    handleAudioUpload = async () => {

        const { audio } = this.state;

        this.setState({
            audio: !audio,
        }, async () => {
            const { audio } = this.state;

            if (recorderEvent === "")
                recorderEvent = await this.recordAudio();

            if (audio) {
                recorderEvent.start();
                recorderTimeOut = setTimeout(() => {
                    this.setState({
                        audio: !audio
                    }, () => {
                        recorderEvent.stop();
                        recorderEvent = "";
                    })
                }, recorderTimeoutTime)
            } else {
                if (recorderTimeOut !== "") {
                    clearTimeout(recorderTimeOut);
                    recorderTimeOut = "";
                }
                recorderEvent.stop();
                recorderEvent = "";
                // audiox.play();
            }
        })
    }

    handlePubNubScroll = () => {
        const firstLi = document.querySelector(
            ".pubnub_scroll_loader  > div"
        );
        const scrollWin = document.querySelector(".pubnub_scroll_loader");
        if (firstLi === null) return;
        var firstLiOffset = (firstLi.offsetTop + firstLi.clientHeight) + 250;
        let pageOffset = scrollWin.scrollTop + scrollWin.scrollHeight;
        if (firstLiOffset > pageOffset) {
            this.handleFetchMessages();
        }
    };

    documentToggle = (e, files, isDoc = false) => {

        if (files !== undefined) {
            const fileExt = files[0].split(".").slice(-1)[0];
            if (fileExt === "pdf") {
                isDoc = true;
            }

            if (isDoc) {
                this.setState({
                    modalClass: "modal-imgdocs modal-docs",
                    documentType: 'application/pdf',
                });
            } else {
                this.setState({
                    modalClass: "modal-imgdocs",
                    documentType: '',
                });
            }
            if (e !== null) {
                e.preventDefault();
                this.setState({
                    documentIndex: $(e.currentTarget).index(),
                    documentCount: $(e.currentTarget).siblings().length
                }, () => {
                    this.setState({
                        showArrow: this.state.documentCount > 1 ? true : false,
                    })
                })

                this.setState({
                    allDocuments: files,
                    dataFile: files[$(e.currentTarget).index()],
                });
            } else {
                this.setState({
                    dataFile: files,
                })
            }
        }

        this.setState({
            modal: !this.state.modal,
        })
    };

    handlePubNubShowCloseSL = (e) => {
        if (e !== null) {
            e.preventDefault();
        }

        if (chatScroll !== "") {
            chatScroll.removeEventListener("scroll", this.handlePubNubScroll, true);
            chatScroll = "";
        }

        if (escListener !== "") {
            escListener = document.removeEventListener('keydown', this.handleEscapeListener, true);
            escListener = "";
        }

        // document.removeEventListener('keydown', this.handleEscapeListener, true);
        // pubnub.unsubscribe({ "channels": [...channels] });
        this.setState({
            // pushChannels: channels,
            channels: [],
            activeChannel: null,
            messages: [],
            patient_details: undefined,
            message: "",
            timetoken: "",
            pubNubPopupOpen: false,
            fetchendTime: new Date().getTime() * 1000,
            noMorePunNubData: false
        }, () => {
            // this.handlePushPubnubMessages();
            this.props.onPubNubChatClose()
        })
    }

    // handlePubNubStateUpdateSL = () => {
    //     this.props.onPubNubNotificationClick();
    // }

    notifyMe = messageData => {

        const { notification_message_text, isFileBefore } = this.state;

        if (notification_message_text === messageData.message && !isFileBefore) return;

        this.setState({
            notification_message_text: messageData.message,
            isFileBefore: false
        })


        if (!("Notification" in window)) {
            alert("This browser does not support system notifications");
        } else if (Notification.permission === "granted") {
            if (typeof messageData.message === 'string' || messageData.message instanceof String) {
                let notification = new Notification(`Chat with ${messageData.name}`, {
                    body: messageData.message,
                    data: messageData
                });
                notification.onclick = (e) => {
                    window.focus();
                    this.handleNotificationClick(e.currentTarget.data);
                };
            }
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    if (typeof messageData.message === 'string' || messageData.message instanceof String) {
                        let notification = new Notification(`Chat with ${messageData.name}`, {
                            body: messageData.message,
                            data: messageData
                        });
                        notification.onclick = (e) => {
                            window.focus();
                            this.handleNotificationClick(e.currentTarget.data);
                        };
                    }
                }
            });
        }
    }

    handleNotificationClick = (data) => {
        let { channels, patient_details } = this.state;

        patient_details = {
            first_name: data.name.split(" ")[0],
            last_name: data.name.split(" ")[1],
            dob: data.dob,
            uhid: undefined
        }

        channels.push(data.channelId);

        this.setState({
            channels,
            patient_details,
            activeChannel: data.channelId,
            pubNubPopupOpen: !this.state.pubNubPopupOpen
        }, () => {
            // this.handlePubNubStateUpdateSL();
            this.handleEventListeners();
            this.handleFetchMessages()
        })
    }

    handleIsFileBefore = () => {
        this.setState({
            isFileBefore: true
        })
    }

    handleAudioCheck = (filename) => {
        return (filename.split(".")[1] === '3gp' || filename.split(".")[1] === 'm4a' || filename.split(".")[1] === 'wav')
    }

    render() {

        const { message, messages, channels, pubNubChannels, patient_details, pubNubPopupOpen, isPubNubFetchRequestProcessing,
            audio, messages_loaded, activeChannel, doctor_uuid } = this.state;

        const PubNubRender = (
            <PubNubProvider client={pubnub}>
                <div className="pubnub-app">
                    <div className="pubnub-appwrapper">

                        <PubNubConsumer>
                            {client => {
                                client.addListener({
                                    message: messageEvent => {
                                        const { activeChannel, pubNubChannels } = this.state;

                                        if (activeChannel !== null) {
                                            if (+activeChannel === +messageEvent.subscribedChannel) {
                                                this.addMessage(messageEvent);
                                            } else {
                                                this.notifyMe(messageEvent.message);
                                            }
                                        } else {
                                            if (pubNubChannels.length > 0) {
                                                this.notifyMe(messageEvent.message);
                                            }
                                        }
                                    },
                                    presence: p => {
                                        // handle presence
                                        // var action = p.action; // Can be join, leave, state-change, or timeout
                                        // var channelName = p.channel; // The channel to which the message was published
                                        // var occupancy = p.occupancy; // No. of users subscribed to the channel
                                        // var state = p.state; // User State
                                        // var channelGroup = p.subscription; //  The channel group or wildcard subscription match (if exists)
                                        // var publishTime = p.timestamp; // Publish timetoken
                                        // var timetoken = p.timetoken;  // Current timetoken
                                        // var uuid = p.uuid; // UUIDs of users who are subscribed to the channel

                                        console.log('Presence')
                                        console.log(p);
                                    },
                                    signal: s => {
                                        // handle signal
                                        // var channelName = s.channel; // The channel to which the signal was published
                                        // var channelGroup = s.subscription; // The channel group or wildcard subscription match (if exists)
                                        // var pubTT = s.timetoken; // Publish timetoken
                                        // var msg = s.message; // The Payload
                                        // var publisher = s.publisher; //The Publisher

                                        // console.log('Signal');
                                        // console.log(s);
                                        this.showTyping(s);
                                    },
                                    objects: objectEvent => {
                                        // var channel = objectEvent.channel; // The channel
                                        // var channelGroup = objectEvent.subscription; // The channel group
                                        // var timetoken = objectEvent.timetoken; // The event timetoken
                                        // var publisher = objectEvent.publisher; // The UUID that triggered this event
                                        // var event = objectEvent.event; // The event name that occurred
                                        // var type = objectEvent.type; // The event type that occurred
                                        // var data = objectEvent.data; // The event data that occurred

                                        console.log('Objects')
                                        console.log(objectEvent);
                                    },
                                    messageAction: ma => {
                                        // handle message action
                                        // var channelName = ma.channel; // The channel to which the message was published
                                        // var publisher = ma.publisher; //The Publisher
                                        // var event = ma.message.event; // message action added or removed
                                        // var type = ma.message.data.type; // message action type
                                        // var value = ma.message.data.value; // message action value
                                        // var messageTimetoken = ma.message.data.messageTimetoken; // The timetoken of the original message
                                        // var actionTimetoken = ma.message.data.actionTimetoken; // The timetoken of the message action

                                        console.log('Message Action')
                                        console.log(ma);
                                    },
                                    file: event => {
                                        // const channelName = event.channel; // Channel to which the file belongs
                                        // const channelGroup = event.subscription; // Channel group or wildcard subscription match (if exists)
                                        // const publisher = event.publisher; // File publisher
                                        // const timetoken = event.timetoken; // Event timetoken
                                        // const message = event.message; // Optional message attached to the file
                                        // const fileId = event.file.id; // File unique id
                                        // const fileName = event.file.name;// File name
                                        // const fileUrl = event.file.url; // File direct URL

                                        // console.log('File')
                                        // console.log(event);

                                        const { activeChannel } = this.state;

                                        this.handleIsFileBefore();

                                        if (activeChannel !== null) {
                                            const message = {
                                                message: {
                                                    message: event.file.name,
                                                    name: event.message.name,
                                                    uuid: event.message.uuid,
                                                    audio: event.message.audio !== undefined ? event.message.audio : this.handleAudioCheck(event.file.name)
                                                },
                                                file_id: event.file.id,
                                                timetoken: event.timetoken,
                                                publisher: event.message.uuid
                                            }

                                            this.addMessage(message)
                                        }
                                    },
                                    status: s => {
                                        // var affectedChannelGroups = s.affectedChannelGroups; // The channel groups affected in the operation, of type array.
                                        // var affectedChannels = s.affectedChannels; // The channels affected in the operation, of type array.
                                        // var category = s.category; //Returns PNConnectedCategory
                                        // var operation = s.operation; //Returns PNSubscribeOperation
                                        // var lastTimetoken = s.lastTimetoken; //The last timetoken used in the subscribe request, of type long.
                                        // var currentTimetoken = s.currentTimetoken; //The current timetoken fetched in the subscribe response, which is going to be used in the next request, of type long.
                                        // var subscribedChannels = s.subscribedChannels; //All the current subscribed channels, of type array.

                                        // console.log('Status')
                                        // console.log(s);
                                    },
                                });

                                if (activeChannel !== null) {
                                    client.subscribe({ channels });
                                } else {
                                    if (pubNubChannels.length > 0) {
                                        client.subscribe({ channels: pubNubChannels });
                                    }
                                }
                            }}
                        </PubNubConsumer>

                        <div className="pubnub-wrapper">
                            <div className="pubnub-common pubnub-header">
                                <div className="pubnub-title">Message Patient</div>

                                {channels.length > 0 && patient_details !== undefined ? (<div className="pubnubheader-patientdetail">
                                    Patient :{" "}
                                    <strong>
                                        {patient_details.first_name + ' ' + patient_details.last_name}
                                    </strong>{" "}
                                    {GETDOB(patient_details.dob)} Yrs,{" "}
                                    {GETGENDER(patient_details.gender)}{" "}
                                    {(patient_details.uhid !== '' && patient_details.uhid !== undefined) ? '| ' + UHID(patient_details.uhid) : ""}
                                </div>) : ""}

                                <a href="#" onClick={this.handlePubNubShowCloseSL}>&times;</a>
                            </div>
                            <div className="pubnub-common pubnub-content pubnub_scroll_loader">
                                <div>
                                    {activeChannel !== null ? (messages.map((mess, messageIndex) => {
                                        if (mess.message !== undefined && mess.message.length > 0) {
                                            // if(mess.file_id) {
                                            //     debugger
                                            // }
                                            return (
                                                <div
                                                    className="pubnub-content-innerwrapper"
                                                    key={`message-${messageIndex}`}
                                                    style={{
                                                        textAlign: +mess.uuid === doctor_uuid ? 'right' : 'left'
                                                    }}
                                                >
                                                    <div
                                                        className="pubnub-contentinner-content"
                                                        style={{
                                                            backgroundColor: +mess.uuid !== doctor_uuid ? '#ffb9b9' : '#ffffff',
                                                        }}
                                                    >
                                                        <p className="mb-2">
                                                            <span>
                                                                {mess.name}
                                                            </span>
                                                            <span>
                                                                <Moment format="h:mm A, D MMM, Y" unix>
                                                                    {new Date(Math.floor(+mess.timetoken / 10000000))}
                                                                </Moment>
                                                            </span>
                                                        </p>
                                                        {mess.file_id ? !mess.audio ? (
                                                            <span style={{
                                                                display: 'inline-block'
                                                            }} onClick={() => this.documentToggle(null, [this.getFileUrl(mess)])}>
                                                                <object title="Document" data={this.getFileUrl(mess)} style={{
                                                                    pointerEvents: 'none',
                                                                    maxWidth: '100px'
                                                                }}></object>
                                                            </span>
                                                        ) : (
                                                                <audio controls>
                                                                    <source src={this.getFileUrl(mess)} />
                                                                    Your browser does not support the audio element.
                                                                </audio>
                                                            ) : mess.message}
                                                        {/* {mess.uuid + ' | ' + doctor_uuid}{"-"}
                                                        {channels[0]} */}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })) : ""}

                                    {messages.length === 0 && isPubNubFetchRequestProcessing ? (
                                        <div className="text-center">
                                            {LOADER_RED}
                                        </div>
                                    ) : messages.length === 0 && messages_loaded ? (
                                        <>
                                            <div className="text-center">
                                                No Messages
                                            </div>
                                        </>
                                    ) : ""}

                                    {/* {isTyping !== null && isTyping.status && +isTyping.uuid !== doctor_uuid ? LOADER_BLACK : ""} */}
                                </div>
                            </div>
                            <form className="pubnub-common pubnub-footer">
                                <input
                                    type="text"
                                    style={{
                                        borderRadius: '5px',
                                        flexGrow: 1,
                                        fontSize: '18px',
                                    }}
                                    id="inputText"
                                    placeholder="Type here"
                                    autoComplete="off"
                                    value={message}
                                    name="message"
                                    onChange={this.setMessage}
                                />
                                <div className="pubnub_footer_buttonwrapper">
                                    <button type="button" className={`icon-mute-btn ${audio ? 'active' : ""}`} title="Microphone" onClick={this.handleAudioUpload}>
                                        <img src="/assets/images/mic.png" alt="mic-icon" />
                                        <span></span>
                                        {/* <label style={{ position: 'absolute', top: '0', bottom: '0', right: '0', left: '0' }} className="m-0" htmlFor="inputAudioFile"></label>
                                            <input className="hidden" id="inputAudioFile" type="file" accept="audio/*" capture />
                                            <audio id="inputAudioFile" controls></audio> */}
                                    </button>
                                    <button className="btn btn-sm btn-primary" type="button">
                                        <label className="m-0" htmlFor="inputFile">Upload</label>
                                        <input id="inputFile" className="hidden" type="file"
                                            accept="image/png, image/jpeg, image/pdf"
                                            onChange={this.handleImageUpload} />
                                    </button>
                                    <button className="btn btn-sm btn-danger" type="submit"
                                        onClick={e => {
                                            this.sendMessage(e, message);
                                        }}
                                    >
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </PubNubProvider>
        )

        return (

            <>
                <div className={`pubnub-container ${pubNubPopupOpen ? 'visible' : 'hidden'}`}>
                    {PubNubRender}
                </div>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.documentToggle}
                    className={this.state.modalClass}
                >
                    <ModalHeader toggle={this.documentToggle}></ModalHeader>
                    <ModalBody>
                        <div className="img-rotate">
                            <i className="ir-left" onClick={HANDLEIMAGEROTATE} name="rotate-left"><img className="rotate-left" src={rotateLeft} /></i>
                            <i className="ir-right" onClick={HANDLEIMAGEROTATE} name="rotate-right"><img className="rotate-left" src={rotateRight} /></i>
                        </div>
                        {/* <div className="img-arrows" style={{ 'display': this.state.showArrow ? 'flex' : 'none' }}>
                            <i className="ir-left" onClick={this.handleNextPrev} name="image-left"><img className="image-left" src={prevImgIcon} /></i>
                            <i className="ir-right" onClick={this.handleNextPrev} name="image-right"><img className="image-left" src={nextImgIcon} /></i>
                        </div> */}
                        <div className="object-wrapper ow-fullheight">
                            <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0" id="objDoc"></object>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default PubNubComponent;