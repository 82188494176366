module.exports = {
  get: function (key, defaultValue) {
    var value = window.localStorage.getItem(key);

    var decoded = JSON.parse(value);

    if (decoded) {
      return decoded;
    }

    return defaultValue;
  },

  set: function (key, value) {
    if (value !== "") window.localStorage.setItem(key, JSON.stringify(value));
    else window.localStorage.removeItem(key);
  },

  edit: function (key, value) {
    // var value = window.localStorage.getItem(key);

    var decoded = JSON.parse(value);

    if (decoded) {
      this.set(key, value);
    }
  },

  delete: function (key) {
    this.set(key, "");
  },

  has: function (key) {
    return window.localStorage.hasOwnProperty(key) ? true : false;
  },

  clear: function () {
    window.localStorage.clear();
  },
};
